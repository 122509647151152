/* eslint-disable */

import React, { useState, useEffect } from "react"
import axios from "axios"
import styled from "@emotion/styled"

import Seo from "../components/seo"
import BreadCrumbs from "../components/breadCrumbs"

const Container = styled.div`
  margin: 16px 20px 128px;
  h1 {
    margin-top: 48px;
    font-weight: 900;
    line-height: 130%;
  }
`

const PrivacyPage = ({ location }) => {
  const [data, setData] = useState("")

  useEffect(() => {
    const fetchPrivacy = async () => {
      const result = await axios(
        "https://www.iubenda.com/api/privacy-policy/89206580/no-markup"
      )
      setData(result.data.content)
    }
    fetchPrivacy()
  }, [])

  return (
    <>
      <Seo
        title="Privacy & Cookie policy"
        description="Privacy e Cookie policy per il sito https://benettifilippini.studio"
      />
      <Container>
        <BreadCrumbs location={location} />
        <div dangerouslySetInnerHTML={{ __html: data }} />
      </Container>
    </>
  )
}

export default PrivacyPage
